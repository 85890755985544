import { z } from "zod";
import { create } from "zustand";

export const PaginationSchema = z.object({
  total: z.number(),
  numPages: z.number(),
  currentPage: z.number(),
  hasNext: z.boolean(),
  hasPrevious: z.boolean(),
  startIndex: z.number(),
  endIndex: z.number(),
});

export type PaginationData = z.infer<typeof PaginationSchema>;

export interface PaginationStore {
  paginationData: PaginationData;
  currentPage: number;
  updatePaginationInfo: (data: PaginationData) => void;
  updateCurrentPage: (value: number) => void;
  resetPaginationStore: () => void;
}

const initialState: PaginationData = {
  total: 0,
  numPages: 0,
  currentPage: 1,
  hasNext: false,
  hasPrevious: false,
  startIndex: 0,
  endIndex: 1,
};

export const usePaginationStore = create<PaginationStore>()((set) => ({
  paginationData: initialState,
  currentPage: 1,
  updatePaginationInfo: (data) => {
    set((state) => {
      return {
        ...state,
        paginationData: data,
      };
    });
  },
  updateCurrentPage: (value) => {
    set((state) => {
      return {
        ...state,
        currentPage: value,
      };
    });
  },
  resetPaginationStore: () => {
    set(initialState);
  },
}));
