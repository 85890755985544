import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { useState } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MainAppLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div className="">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarClose={() => setSidebarOpen(false)} />

        <div className="lg:pl-72 h-screen overflow-auto bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-orange-100 via-amber-100 via-30% to-gray-100 to-60%">
          <ToastContainer
            position="top-center"
            autoClose={3500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
            theme="light"
          />
          <Navbar setSidebarOpen={() => setSidebarOpen(true)} />
          <main className="lg:py-10 mt-2 lg:mt-0 px-4 sm:px-6 lg:px-8 h-full">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default MainAppLayout;
