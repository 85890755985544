const encode = (value: string | number | boolean) => {
  return encodeURIComponent(value)
    .replace(/%3A/gi, ":")
    .replace(/%24/g, "$")
    .replace(/%2C/gi, ",")
    .replace(/%20/g, "+")
    .replace(/%5B/gi, "[")
    .replace(/%5D/gi, "]");
};

export const buildUrl = (baseUrl: string, searchParams?: Record<string, string | number | undefined | null>) => {
  let url = baseUrl;
  //   debugger;
  if (searchParams) {
    url += "?";
    for (const key in searchParams) {
      const value = searchParams[key];
      if (value) {
        if (!url.endsWith("?")) {
          url += "&";
        }
        url += `${key}=${encode(value)}`;
      }
    }
  }
  return url;
};
