import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance, defaultGetFetcher, handleAxiosError, IErrorResponse } from "../../../api/base";
import { z } from "zod";
import { Card } from "../../../components/card";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon, XMarkIcon, UserIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import useSWRImmutable from "swr/immutable";
import { camelCaseToNormal } from "../../../util/string";
import { Fragment, useEffect, useRef, useState } from "react";
import logoIcon from "@assets/logoIcon.png";
import useSWRMutation from "swr/mutation";
import { toast } from "react-toastify";

//#region zod schemas

const LeadRequestDetailsDtoSchema = z.object({
  id: z.number(),
  created: z.date(),
  email: z.string().optional(),
  mixpanelAnonUserId: z.string(),
  status: z.string(),
  sourceType: z.string(),
  rangeOfAssets: z.string().optional(),
  firstName: z.string(),
  lastName: z.string(),
  leadInfo: z.string(),
  phoneNumber: z.string(),
  utmData: z.record(z.unknown()),
  notes: z.string(),
  googleClientId: z.string(),
});

const MatchedAdvisorDtoSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  title: z.string().optional(),
  explanation: z.string().optional(),
  signUpDate: z.string(),
  legalConfirmationDate: z.string().optional(),
  additionalProfileInformation: z.record(z.unknown()),
  gender: z.string().optional(),
  bio: z.string().optional(),
  certifications: z.array(z.string()),
});

const SupabaseConversationEntrySchema = z.object({
  log_id: z.number(),
  ai_msg: z.string(),
  user_msg: z.string(),
  is_predefined_ai_question: z.boolean().default(false),
});

const AIMessageSchema = z.object({
  id: z.number(),
  message: z.string(),
  sentOn: z.string(),
  isAiResponse: z.boolean(),
  messageAuthorId: z.number().nullable(),
  messageAuthorName: z.string().nullable(),
});

const LeadRequestConversationResultSchema = z.object({
  leadRequestDetails: LeadRequestDetailsDtoSchema,
  userResponseWithExplanations: z.array(MatchedAdvisorDtoSchema),
  conversationLog: z.array(SupabaseConversationEntrySchema),
  additionalAiMessages: z.array(AIMessageSchema),
});

type LeadRequestDetailsDto = z.infer<typeof LeadRequestDetailsDtoSchema>;
type MatchedAdvisorDto = z.infer<typeof MatchedAdvisorDtoSchema>;
type SupabaseConversationEntryDto = z.infer<typeof SupabaseConversationEntrySchema>;

type AIMessage = z.infer<typeof AIMessageSchema>;
interface AIMessageResponse extends z.infer<typeof AIMessageSchema> {}

type LeadRequestConversationResult = z.infer<typeof LeadRequestConversationResultSchema>;
//#endregion

const executeSendMessageToAIRequest = async (url: string, { arg }: { arg: string }) => {
  const response = await axiosInstance.post<AIMessageResponse>(url, { message: arg });
  return AIMessageSchema.parse(response.data);
};

export const LeadRequestDetailsPage = () => {
  const { leadRequestId } = useParams();
  const navigate = useNavigate();
  let [isAIModalOpen, setIsAIModalOpen] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const scrollToBottomRef = useRef<HTMLDivElement>(null);
  const {
    data,
    isLoading,
    error: loadingError,
  } = useSWRImmutable<LeadRequestConversationResult, IErrorResponse>(
    `api/v2/admin/advertising/lead/${leadRequestId}/ai-summary`,
    (url: string) => defaultGetFetcher(url),
  );
  const [messages, setMessages] = useState<AIMessage[]>([]);

  const pushMessage = (message: AIMessage) => {
    setMessages((prevState) => [...prevState, message]);
  };

  useEffect(() => {
    if (data) {
      setMessages((prevState) => [...prevState, ...data.additionalAiMessages]);
    }
  }, [data]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (!scrollToBottomRef.current) {
        console.log("scrollToBottomRef is null", new Date().toTimeString());
        return;
      }
      console.log("scrolling to bottom", new Date().toTimeString());
      scrollToBottomRef.current.scrollIntoView();
      document.documentElement.scrollTop = scrollToBottomRef.current.offsetTop;
    }, 10);
  };

  useEffect(() => {
    scrollToBottom();
  }, [isAIModalOpen]);

  const { trigger: sendMessageToAI, isMutating: isSendingMessageToAi } = useSWRMutation(
    `api/v2/admin/advertising/lead/${leadRequestId}/ai-message`,
    executeSendMessageToAIRequest,
  );

  const onSendButtonClick = async () => {
    const toastId = toast.loading("Sending message, waiting for AI response...", {
      pauseOnHover: false,
    });

    if (textAreaRef.current === null || textAreaRef.current.value === "") return;

    pushMessage({
      id: Math.random() * 10000,
      isAiResponse: false,
      messageAuthorName: "You",
      messageAuthorId: 18,
      sentOn: new Date().toISOString(),
      message: textAreaRef.current!.value,
    });
    scrollToBottom();

    try {
      const data = await sendMessageToAI(textAreaRef.current.value);
      textAreaRef.current.value = "";
      pushMessage(data);
      toast.update(toastId, { render: "AI Response received!", type: "success", isLoading: false, autoClose: 3500 });
    } catch (e) {
      const errorResponse = handleAxiosError(e);
      toast.update(toastId, {
        render: errorResponse.errorDetails ?? errorResponse.error,
        type: "error",
        isLoading: false,
        autoClose: 3500,
      });
    } finally {
      scrollToBottom();
    }
  };

  const onCancel = () => {
    navigate(`/lead-requests`);
  };

  if (isLoading) {
    return (
      <div className="lg:h-full overflow-y-auto">
        <header>
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Lead request details</h1>
        </header>
        <div className="mt-4">
          <Card className="w-full h-full px-4 mb-2 border border-gray-100">
            <ul role="list" className="divide-y divide-gray-100">
              <li className="flex flex-col gap-y-2 py-5">
                <div className="flex items-start gap-x-2">
                  <p className="text-sm font-semibold leading-6 text-gray-900/80">Loading...</p>
                </div>
                <div className="w-full h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
                <div className="w-1/4 h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
                <div className="w-3/4 h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
              </li>
            </ul>
          </Card>
        </div>
      </div>
    );
  }

  if (loadingError !== undefined || data === undefined) {
    let handledError: IErrorResponse;
    if (loadingError) handledError = handleAxiosError(loadingError);
    else
      handledError = {
        error: "Data is undefined",
        errorDetails: "Some unexpected error occurred. Please try again later or contact support.",
      };

    return (
      <div className="lg:h-full overflow-y-auto">
        <header>
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Lead request details</h1>
        </header>
        <div className="mt-4">
          <Card className="w-full h-full mb-2 border border-gray-100 flex flex-col divide-y-0">
            <div className="flex flex-col gap-y-4">
              <p className="font-semibold leading-6 text-gray-900/80">Could not load request</p>

              <div className="w-full text-sm">
                <p>{handledError.error}</p>
                <p>{handledError.errorDetails}</p>
              </div>
              <div className="w-full flex justify-start">
                <button
                  onClick={onCancel}
                  className="text-gray-900 border border-gray-300 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Go back
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  const getLeadInformation = (data: LeadRequestDetailsDto) => {
    const fields = [
      { label: "Full name", value: `${data.firstName} ${data.lastName}` },
      { label: "Email address", value: data.email },
      { label: "Mixpanel Anon User ID", value: data.mixpanelAnonUserId },
      { label: "Status", value: data.status },
      { label: "Source Type", value: data.sourceType },
      { label: "Range of Assets", value: data.rangeOfAssets },
      { label: "Lead Info", value: data.leadInfo },
      { label: "Phone Number", value: data.phoneNumber },
      { label: "UTM Data", value: JSON.stringify(data.utmData) },
      { label: "Notes", value: data.notes },
      { label: "Google Client ID", value: data.googleClientId },
    ];

    return (
      <div>
        <div className="px-4 sm:px-0">
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {fields.map((field, index) => (
              <div key={index} className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-xs font-medium leading-6 text-gray-900">{field.label}</dt>
                <dd className="mt-1 text-xs leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{field.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    );
  };

  const getAdvisorAiSummary = (data: MatchedAdvisorDto[]) => {
    return (
      <div>
        <div className="px-4 sm:px-0">
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p>
        </div>
        <div className="mt-6 divide-y-2 border-t-2 border-primary-200 divide-primary-200 flex flex-col gap-y-2">
          {data.length === 0 && (
            <div className="flex w-full h-full items-center justify-center text-gray-400 text-sm mt-2">
              <p className="font-medium text-gray-900">
                User hasn't completed survey. No advisors were found for this lead request.
              </p>
            </div>
          )}
          {data.map((advisor, _) => (
            <dl className="divide-y divide-gray-100" key={advisor.id}>
              <div key="advisorId" className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-xs font-medium leading-6 text-gray-900">Advisor</dt>
                <dd className="mt-1 text-xs leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {advisor.firstName + " " + advisor.lastName} ({advisor.id})
                </dd>
              </div>
              {Object.entries(advisor)
                .filter((key) => !["id", "firstName", "lastName", "additionalProfileInformation"].includes(key[0]))
                .map((key, _) => {
                  let value = "N/A";
                  if (key[1] === null) value = "N/A";
                  else if (key[0] === "certifications") {
                    value = (key[1] as string[]).join(", ");
                  } else if (key[0] === "additionalProfileInformation") {
                    value = JSON.stringify(key[1], null, 2);
                  } else {
                    value = String(key[1]);
                  }

                  return (
                    <div
                      key={`${advisor.id} - ${key[0]}`}
                      className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                    >
                      <dt className="text-xs font-medium leading-6 text-gray-900">{camelCaseToNormal(key[0])}</dt>
                      <dd className="mt-1 text-xs leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{value}</dd>
                    </div>
                  );
                })}
            </dl>
          ))}
        </div>
      </div>
    );
  };

  const getLeadConversationLog = (data: SupabaseConversationEntryDto[], sourceType: string) => {
    return (
      <div>
        <div className="px-4 sm:px-0">
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            {sourceType === "AI" ? "Conversation log with AI." : "Read lead survey answers"}
          </p>
        </div>
        <div className="mt-6 border-t-2 border-primary-200 divide-primary-200">
          <ul role="list">
            {data.length === 0 && (
              <li>
                <div className="flex w-full h-full items-center justify-center text-gray-400 text-sm mt-2">
                  <p className="font-medium text-gray-900">User hasn't answered any questions.</p>
                </div>
              </li>
            )}
            {data.map((entry, index) => (
              <li key={index}>
                <div className="relative pb-4 flex flex-row">
                  {index !== data.length - 1 ? (
                    // Vertical column line
                    <span aria-hidden="true" className="absolute left-3 top-4 -ml-px h-full w-0.5 bg-gray-200" />
                  ) : null}
                  <>
                    <div className="relative flex h-6 w-6 pt-1 flex-none items-center justify-center bg-white">
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    </div>
                    <div className="flex-auto flex-col py-0.5 pt-1 text-xs leading-5 text-gray-500">
                      <p className="">
                        <span className="font-medium text-gray-900">Question:</span> {entry.ai_msg}
                      </p>
                      <p className="">
                        <span className="font-medium text-gray-900">Answer:</span> {entry.user_msg}
                      </p>
                    </div>
                  </>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const formatMessageDate = (messageDate: string) => {
    const today = new Date();
    const date = new Date(messageDate);
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    } else {
      return date.toLocaleString();
    }
  };

  const renderMessage = (message: AIMessage) => {
    return (
      <div className={clsx("flex items-start gap-2.5", { "flex-row-reverse": !message.isAiResponse })}>
        <div className="flex flex-col gap-y-2 w-full max-w-[640px] leading-1.5 p-4  ">
          <div className={clsx("flex items-center gap-2", { "flex-row-reverse": !message.isAiResponse })}>
            {message.isAiResponse && <img className="h-8 w-8 rounded" src={logoIcon} alt="AI" />}
            <span className="text-sm font-semibold text-gray-900 ">
              {message.isAiResponse ? "AI" : message.messageAuthorName}
            </span>
            <span className="text-sm font-normal text-gray-500">{formatMessageDate(message.sentOn)}</span>
            {!message.isAiResponse && <UserIcon className="h-8 w-8 rounded text-gray-500" />}
          </div>
          <p
            className={clsx(
              "text-xs font-normal py-2.5 text-gray-900 leading-relaxed bg-gray-200/75 px-2 rounded-lg",
              { "rounded-tl-none": message.isAiResponse },
              { "rounded-tr-none": !message.isAiResponse },
            )}
          >
            {message.message}
          </p>
        </div>
      </div>
    );
  };

  const renderDisclosureContent = (title: string, open: boolean, content: JSX.Element) => {
    return (
      <>
        <Disclosure.Button
          className={clsx(
            "flex w-full justify-between items-center my-2 py-2 px-4 text-left rounded-lg bg-primary-50/75 hover:bg-primary-100 focus:outline-none focus-visible:ring focus-visible:ring-primary-500/75",
            { "bg-primary-100": open },
          )}
        >
          <h3 className="text-base font-semibold leading-7 text-gray-900">{title}</h3>
          <ChevronRightIcon className={clsx("h-5 w-5 text-primary-500", { "rotate-90 transform": open })} />
        </Disclosure.Button>
        <Transition
          show={open}
          className="transition-all duration-500 overflow-hidden"
          enterFrom="transform scale-95 opacity-0 h-0"
          enterTo="transform scale-100 opacity-100"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0 max-h-0"
        >
          <Disclosure.Panel className="text-gray-500 mt-2">
            <div className="flex flex-col w-full gap-y-6 mb-6 px-4">{content}</div>
          </Disclosure.Panel>
        </Transition>
      </>
    );
  };

  return (
    <div className="lg:h-full overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Lead request details</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full mb-2 border border-gray-100 flex flex-col divide-y-0">
          <Disclosure defaultOpen>
            {({ open }) =>
              renderDisclosureContent("Applicant Information", open, getLeadInformation(data.leadRequestDetails))
            }
          </Disclosure>
          <Disclosure>
            {({ open }) =>
              renderDisclosureContent(
                "Conversation log",
                open,
                getLeadConversationLog(data.conversationLog, data.leadRequestDetails.sourceType),
              )
            }
          </Disclosure>
          <Disclosure>
            {({ open }) =>
              renderDisclosureContent("AI Summary", open, getAdvisorAiSummary(data.userResponseWithExplanations))
            }
          </Disclosure>
          <div className="flex justify-between px-4 py-2">
            <button
              onClick={onCancel}
              className="text-gray-900 border border-gray-300 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              Cancel
            </button>

            <button
              onClick={() => setIsAIModalOpen(true)}
              disabled={data.userResponseWithExplanations.length === 0}
              className="
              text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center
              disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:bg-gray-300 disabled:cursor-not-allowed
              "
            >
              AI Rematch
            </button>
          </div>
        </Card>
      </div>
      <Transition appear show={isAIModalOpen} as={Fragment}>
        <Dialog open={isAIModalOpen} onClose={() => setIsAIModalOpen(false)} className="relative z-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="ease-in-out duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-full sm:max-w-4xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            AI Rematch
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              onClick={() => setIsAIModalOpen(false)}
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-4 px-4 sm:px-6 flex flex-1 flex-col gap-y-4">
                        <div>
                          <p className="text-sm text-gray-500">
                            You can ask AI to rematch advisors for this lead request. We will send your additional
                            information to AI Algorithm.
                          </p>
                        </div>

                        <div className="flex flex-grow max-h-[460px] sm:max-h-[900px] flex-col gap-y-4 bg-gray-50 rounded-lg px-4 py-4 overflow-y-scroll">
                          {messages.length === 0 && (
                            <div className="flex w-full h-full items-center justify-center text-gray-400 text-sm">
                              No messages. Send your first message below.
                            </div>
                          )}
                          {messages.map((message, index) => (
                            <div key={index}>{renderMessage(message)}</div>
                          ))}
                          <div ref={scrollToBottomRef} id={"scrollToBottom"}></div>
                        </div>

                        <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                          <div className="col-span-full">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                              Prompt
                            </label>
                            <div className="mt-2">
                              <textarea
                                ref={textAreaRef}
                                id="about"
                                name="about"
                                rows={3}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-300 text-xs sm:leading-6"
                                defaultValue={
                                  "Find one best match for advisor. Here is additional information about user: "
                                }
                              />
                            </div>

                            <div className="mt-4 flex justify-between align-middle">
                              <p className="text-xs leading-6 text-gray-500">
                                Write a additional information about user, edit prompt as you see fit. We will forward
                                your prompt with user and advisor data to AI Algorithm.
                              </p>
                              <div className="flex h-full self-center">
                                <button
                                  type="button"
                                  disabled={isSendingMessageToAi}
                                  className="flex justify-center rounded-md border border-transparent bg-primary-100 disabled:bg-gray-100 px-4 py-2 text-sm font-medium text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                                  onClick={onSendButtonClick}
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
