import { CardWithHeading } from "../../../components/card";
import { ExclamationTriangleIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import * as zod from "zod";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { BASE_URL, IErrorResponse, axiosInstance } from "../../../api/base";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input, Select, TextAreaInput } from "../../../components/formInputs";

// const FeeTypes = [
//   { value: 1, displayValue: "Flat fee hourly" },
//   { value: 2, displayValue: "Flat fee monthly" },
//   { value: 3, displayValue: "Flat fee yearly" },
//   { value: 4, displayValue: "Percentage of assets" },
//   { value: 5, displayValue: "Flexible" },
// ];

// const GenderOptions = [
//   { value: 1, displayValue: "Male" },
//   { value: 2, displayValue: "Female" },
//   { value: 3, displayValue: "No preference" },
// ];

const RangeOfAssets = [
  { value: 1, displayValue: "< $100K" },
  { value: 2, displayValue: "$100K - $250K" },
  { value: 3, displayValue: "$250K - $500K" },
  { value: 4, displayValue: "$500K - $1M" },
  { value: 5, displayValue: "$1M - $5M" },
  { value: 6, displayValue: "> $5M" },
];

const SearchType = [
  { value: 1, displayValue: "Only parameters" },
  //{ value: 2, displayValue: "Parameters with embedding" },
  { value: 3, displayValue: "AI Matching" },
];

interface AdvisorResult {
  id: number;
  advisorProfileId: number;
  positiveScore: number;
  badClientScore: number;
  totalScore: number;
  fullName: string;
  gptExplanation: string | null;
}

interface SearchAdvisorResponse extends IErrorResponse {
  advisors: AdvisorResult[];
}

const searchQuerySchema = zod.object({
  // gopt: zod.coerce.number({ description: "Gender option" }).default(3),
  ropt: zod.preprocess(
    (x) => (x ? (String(x) === "" ? null : Number(x)) : null),
    zod.number({ description: "Net worth " }).nullish(),
  ),
  st: zod.preprocess(
    (x) => (x ? (String(x) === "" ? null : String(x)) : null),
    zod.string({ description: "State" }).nullish().default(null),
  ),
  // ft: zod.preprocess(
  //   (x) => (x ? (String(x) === "" ? null : Number(x)) : null),
  //   zod.number({ description: "Fee type" }).nullish(),
  // ),
  searchText: zod.string().default(""),
  searchType: zod.coerce.number({ description: "Search type" }).default(3),
});
export type SearchQuery = zod.infer<typeof searchQuerySchema>;

const setSearchRequest = async (url: string, { arg }: { arg: SearchQuery }) => {
  const response = await axiosInstance.post<SearchAdvisorResponse>(url, arg);
  return response.data;
};

const SearchAdvisors = () => {
  const [isInitialView, setIsInitialView] = useState(true);
  const [advisorResults, setAdvisorResults] = useState<AdvisorResult[]>([]);
  const { trigger, isMutating, error } = useSWRMutation(`api/v2/admin/advisors/search/ai`, setSearchRequest);
  const formMethods = useForm({
    mode: "onSubmit",
    resolver: zodResolver(searchQuerySchema),
    defaultValues: searchQuerySchema.parse({}),
  });

  const onSubmit = async (data: SearchQuery) => {
    setIsInitialView(false);
    const responseData = await trigger(data);
    setAdvisorResults(responseData.advisors);
  };

  return (
    <>
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">AI Matching</h1>
      </header>
      <div className="mt-4">
        <CardWithHeading header={<h2>Find best advisor matches using AI</h2>}>
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col text-sm text-gray-600 gap-y-2">
              <p>Please provide criteria for AI Matching.</p>
            </div>
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit(onSubmit)}
                className="w-full flex items-start flex-col gap-y-2 my-4"
              >
                <div className="w-full">
                  {/* <Select id="gopt" label="Gender">
                    {GenderOptions.map((g) => (
                      <option key={g.value} value={g.value}>
                        {g.displayValue}
                      </option>
                    ))}
                  </Select> */}
                  <Select id="ropt" label="Net worth" placeholder=" ">
                    {RangeOfAssets.map((r) => (
                      <option key={r.value} value={r.value}>
                        {r.displayValue}
                      </option>
                    ))}
                  </Select>
                  {/* <Select id="ft" label="Fee type" placeholder=" ">
                    {FeeTypes.map((r) => (
                      <option key={r.value} value={r.value}>
                        {r.displayValue}
                      </option>
                    ))}
                  </Select> */}
                  <Input id="st" label="State" />
                  <TextAreaInput id="searchText" label="Summary" />
                  <Select id="searchType" label="Search type">
                    {SearchType.map((r) => (
                      <option key={r.value} value={r.value}>
                        {r.displayValue}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex justify-end w-full">
                  <button
                    type="submit"
                    className="inline-flex justify-end gap-x-1.5 w-full items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:w-auto"
                  >
                    Search
                    <MagnifyingGlassIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </form>
            </FormProvider>
            <div className="w-full h-full overflow-x-auto">
              <div className="flex justify-center text-gray-700 uppercase font-bold mb-4">
                <h3>Results</h3>
              </div>
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 w-full">
                  <tr className="w-full">
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Adv. Id
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap ">
                      Full name
                    </th>
                    {formMethods.getValues().searchType == 2 && (
                      <>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap text-center">
                          Total score
                        </th>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap text-center">
                          Scores (Positive/Bad)
                        </th>
                      </>
                    )}
                    {formMethods.getValues().searchType == 3 && (
                      <>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap text-center">
                          Explanation
                        </th>
                      </>
                    )}
                    <th scope="col" className="px-6 py-3 whitespace-nowrap text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {isMutating && <TableLoading />}
                  {!isMutating && error && <TableError errorMessage={error.message} />}
                  {!isMutating && !error && (
                    <TableDataRows
                      advisors={advisorResults}
                      isInitialView={isInitialView}
                      searchType={formMethods.getValues().searchType}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </CardWithHeading>
      </div>
    </>
  );
};

export default SearchAdvisors;

const formatPercentage = (value: number, locale = "en-GB") => {
  return Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  }).format(value);
};

const TableDataRows = ({
  advisors,
  isInitialView,
  searchType,
}: {
  advisors: AdvisorResult[];
  isInitialView: boolean;
  searchType: number;
}) => {
  return (
    <>
      {advisors.length === 0 && (
        <tr className="bg-white border-b">
          <th
            scope="row"
            colSpan={searchType === 3 ? 4 : 5}
            className="px-6 py-4 font-medium text-red-900 whitespace-nowrap "
          >
            <span className="flex gap-x-2 justify-center items-center">
              {!isInitialView && <ExclamationTriangleIcon className="w-4 h-4 text-red-600 font-bold" />}
              {isInitialView ? "Please run query to show results!" : "No advisors to show!"}
            </span>
          </th>
        </tr>
      )}
      {advisors.map((adv) => (
        <tr className="bg-white border-b" key={adv.id}>
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
            {adv.id}
          </th>
          <td className="px-4 py-2 whitespace-nowra max-w-[200px] truncate">{adv.fullName}</td>

          {searchType != 3 && (
            <>
              <td className="px-4 py-2 whitespace-nowra max-w-[200px] truncate text-center">
                {formatPercentage(adv.totalScore)}
              </td>
              <td className="px-4 py-2 whitespace-nowra max-w-[200px] truncate text-center">
                {formatPercentage(adv.positiveScore)} / {formatPercentage(adv.badClientScore)}
              </td>
            </>
          )}
          {searchType == 3 && <td className="px-4 py-2 whitespace-nowra truncate text-xs">{adv.gptExplanation}</td>}

          <td className="relative whitespace-nowrap py-2 px-4 text-center text-sm font-medium">
            <a
              href={`${BASE_URL}main-admin/core/advisor/${adv.id}/change/`}
              target="_blank"
              className="inline-block px-4 text-white text-center text-xs bg-primary-700 hover:bg-primary-800
              focus:ring-4 focus:ring-primary-300 font-medium rounded-lg py-1 focus:outline-none mr-2"
            >
              View advisor
            </a>
            <a
              href={`${BASE_URL}main-admin/core/advisorprofileinformation/${adv.advisorProfileId}/change/`}
              target="_blank"
              className="inline-block px-4 text-white text-center text-xs bg-primary-700 hover:bg-primary-800
              focus:ring-4 focus:ring-primary-300 font-medium rounded-lg py-1 focus:outline-none"
            >
              View profile
            </a>
          </td>
        </tr>
      ))}
    </>
  );
};

const TableLoading = () => {
  return Array.from({ length: 10 }, (_, index) => {
    return (
      <tr key={index}>
        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
          <div className="px-3 py-1 text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </th>
        <td className="px-6 py-4">
          <div className="px-3 py-1 text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
      </tr>
    );
  });
};

const TableError = ({ errorMessage }: { errorMessage: string }) => {
  return [
    <tr key={"error"}>
      <th scope="row" colSpan={4} rowSpan={10} className="px-6 py-4 font-medium text-red-900 whitespace-nowrap ">
        <span className="flex gap-x-2 justify-center items-center">
          <ExclamationTriangleIcon className="w-4 h-4 text-red-600 font-bold" />
          {errorMessage}
        </span>
      </th>
    </tr>,
    ...Array.from({ length: 9 }, (_, index) => {
      return (
        <tr key={index} className="px-6 py-4">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "></th>
        </tr>
      );
    }),
  ];
};
