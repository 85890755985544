import clsx from "clsx";
import { ReactNode } from "react";

interface CardWithHeadingProps {
  header?: ReactNode;
  children?: ReactNode;
}
export const CardWithHeading = (props: CardWithHeadingProps) => {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-lg">
      <div className="px-4 py-5 sm:px-6 font-bold">{props.header}</div>
      <div className="px-4 py-5 sm:p-6">{props.children}</div>
    </div>
  );
};

interface CardProps extends React.PropsWithChildren {
  className?: string;
}
export const Card = (props: CardProps) => {
  return (
    <div
      className={clsx(
        "divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow px-4 py-5 sm:p-6",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
