import { LoaderFunctionArgs, Outlet, createBrowserRouter } from "react-router-dom";
import Auth from "./pages/auth/Auth.tsx";
import ProtectedRoute from "./util/ProtectedRoute";
import PasswordReset from "./pages/auth/ForgotPassword.tsx";
import MainAppLayout from "./pages/app/MainAppLayout.tsx";
import SignOut from "./pages/auth/SignOut.tsx";
import SignIn from "./pages/auth/SignIn.tsx";
import Dashboard from "./pages/app/dashboard/Dashboard.tsx";
import WebinarPrograms from "./pages/app/webinar-programs/WebinarPrograms.tsx";
import CreateWebinarProgram from "./pages/app/webinar-programs/create/CreateWebinarProgram.tsx";
import CreateWebinarProgramStep1 from "./pages/app/webinar-programs/create/CreateWebinarProgramStep1.tsx";
import CreateWebinarProgramStep2 from "./pages/app/webinar-programs/create/CreateWebinarProgramStep2.tsx";
import CreateWebinarProgramStep3 from "./pages/app/webinar-programs/create/CreateWebinarProgramStep3.tsx";
import EditWebinarProgram from "./pages/app/webinar-programs/edit/EditWebinarProgram.tsx";
import EditWebinarProgramStep1 from "./pages/app/webinar-programs/edit/EditWebinarProgramStep1.tsx";
import EditWebinarProgramStep2 from "./pages/app/webinar-programs/edit/EditWebinarProgramStep2.tsx";
import EditWebinarProgramStep3 from "./pages/app/webinar-programs/edit/EditWebinarProgramStep3.tsx";
import SearchAdvisors from "./pages/app/search/SearchAdvisors.tsx";
import LeadRequestsTable from "./pages/app/lead-requests/LeadRequestsList.tsx";
import { LeadRequestDetailsPage } from "./pages/app/lead-requests/LeadRequestsDetails.tsx";
import { usePaginationStore } from "./store/pagePaginationStore.ts";

const resetPaginationData = (_: LoaderFunctionArgs<any>) => {
  usePaginationStore.getState().resetPaginationStore();
  return null;
};
const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "signout",
        element: <SignOut />,
      },
      {
        path: "forgot-password",
        element: <PasswordReset />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <MainAppLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: "", element: <Dashboard /> },
      {
        path: "programs",
        element: <Outlet />,
        loader: resetPaginationData,
        children: [
          {
            path: "",
            element: <WebinarPrograms />,
          },
          {
            path: "create",
            children: [
              { path: "", element: <CreateWebinarProgram /> },
              { path: "step-1", element: <CreateWebinarProgramStep1 /> },
              { path: "step-2", element: <CreateWebinarProgramStep2 /> },
              { path: "step-3", element: <CreateWebinarProgramStep3 /> },
            ],
          },
          {
            path: "edit/:webinarId",
            children: [
              { path: "", element: <EditWebinarProgram /> },
              { path: "step-1", element: <EditWebinarProgramStep1 /> },
              { path: "step-2", element: <EditWebinarProgramStep2 /> },
              { path: "step-3", element: <EditWebinarProgramStep3 /> },
            ],
          },
        ],
      },
      {
        path: "lead-requests",
        element: <Outlet />,
        loader: resetPaginationData,
        children: [
          {
            path: "",
            element: <LeadRequestsTable />,
          },
          { path: ":leadRequestId/details", element: <LeadRequestDetailsPage /> },
        ],
      },
      { path: "search", element: <SearchAdvisors /> },
      { path: "user/my-profile", element: <Dashboard /> },
    ],
  },
]);

export default router;
