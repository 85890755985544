import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { WebinarProgram } from "./WebinarPrograms";

export const TableLoading = () => {
  return Array.from({ length: 10 }, (_, index) => {
    return (
      <tr key={index}>
        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
          <div className="px-3 py-1 text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </th>
        <td className="px-6 py-4">
          <div className="px-3 py-1 text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
      </tr>
    );
  });
};

export const TableError = ({ errorMessage }: { errorMessage: string }) => {
  return [
    <tr key={"error"}>
      <th scope="row" colSpan={4} rowSpan={10} className="px-6 py-4 font-medium text-red-900 whitespace-nowrap ">
        <span className="flex gap-x-2 justify-center items-center">
          <ExclamationTriangleIcon className="w-4 h-4 text-red-600 font-bold" />
          {errorMessage}
        </span>
      </th>
    </tr>,
    ...Array.from({ length: 9 }, (_, index) => {
      return (
        <tr key={index} className="px-6 py-4">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "></th>
        </tr>
      );
    }),
  ];
};

export const EmptyTableData = () => {
  return [
    <tr key={"empty"}>
      <th scope="row" colSpan={4} rowSpan={10} className="px-6 py-4 font-medium  whitespace-nowrap ">
        <span className="flex gap-x-2 justify-center items-center">No data available</span>
      </th>
    </tr>,
    ...Array.from({ length: 9 }, (_, index) => {
      return (
        <tr key={index} className="px-6 py-4">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "></th>
        </tr>
      );
    }),
  ];
};

export const TableDataRows = ({ programs }: { programs: WebinarProgram[] }) => {
  return (
    <>
      {programs.map((c) => (
        <tr className="bg-white border-b" key={c.id}>
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
            {c.title}
          </th>
          <td className="px-4 py-2 whitespace-nowra max-w-[200px] truncate">{c.createdBy}</td>

          <td className="px-4 py-2">
            {c.isAssetManagementProgram ? (
              <CheckCircleIcon className="h-4 w-4 text-green-500 mx-auto" />
            ) : (
              <XCircleIcon className="h-4 w-4 text-red-500 mx-auto" />
            )}
          </td>

          <td className="px-4 py-2 whitespace-nowrap">{new Date(c.createdOn).toLocaleString()}</td>

          <td className="px-4 py-2">
            {c.isActive ? (
              <CheckCircleIcon className="h-4 w-4 text-green-500 mx-auto" />
            ) : (
              <XCircleIcon className="h-4 w-4 text-red-500 mx-auto" />
            )}
          </td>

          <td className="px-4 py-2">
            <Link
              to={`/programs/edit/${c.id}`}
              className="block mx-auto text-white text-center text-xs bg-primary-700 hover:bg-primary-800
              focus:ring-4 focus:ring-primary-300 font-medium rounded-lg py-1 focus:outline-none"
            >
              Edit
            </Link>
          </td>
        </tr>
      ))}
    </>
  );
};
