import clsx from "clsx";
import { useFormContext, RegisterOptions, Controller } from "react-hook-form";
import { ArrowUpCircleIcon, ExclamationTriangleIcon, PhotoIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { ChangeEvent, Children, ReactNode, cloneElement, isValidElement, useRef, useState } from "react";
import useSWRMutation from "swr/mutation";
import { toast } from "react-toastify";
import WYSIWYGEditor from "./richTextEditor";
import { axiosInstance, handleAxiosError } from "../api/base";

export type InputProps = {
  label: string;
  id: string;
  placeholder?: string;
  helperText?: string;
  type?: string;
  readOnly?: boolean;
  validation?: RegisterOptions;
} & React.ComponentPropsWithoutRef<"input">;

export const Input = ({
  label,
  placeholder = "",
  helperText,
  id,
  type = "text",
  readOnly = false,
  validation,
  ...rest
}: InputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <input
        {...register(id, validation)}
        {...rest}
        type={type}
        name={id}
        id={id}
        readOnly={readOnly}
        className={clsx(
          "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5",
          { "focus:ring-red-500 border-red-500 focus:border-red-500": errors[id] !== undefined },
          { "bg-white border-0 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300": readOnly },
        )}
      />
      <div className="mt-1">
        {helperText && <p className="mt-1 italic text-xs text-gray-400">{helperText}</p>}
        {errors[id] && (
          <div className="text-sm text-red-500 flex flex-row align-middle items-start gap-x-2">
            <ExclamationTriangleIcon className="h-4 w-4 my-auto" />
            <span className="">{errors[id]!.message?.toString()}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export type PlainTextRowProps = {
  label: string;
  text: string;
  helperText?: string;
};
export const PlainTextRow = ({ label, helperText, text }: PlainTextRowProps) => {
  return (
    <div>
      <label htmlFor={text} className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <p
        className={clsx(
          "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5",
        )}
      >
        {text}
      </p>
      <div className="mt-1">{helperText && <p className="mt-1 italic text-xs text-gray-400">{helperText}</p>}</div>
    </div>
  );
};

export const CheckboxInput = ({
  label,
  placeholder = "",
  helperText,
  id,
  type = "text",
  readOnly = false,
  validation,
  ...rest
}: InputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className="flex items center">
        <input
          {...register(id, validation)}
          {...rest}
          type="checkbox"
          name={id}
          id={id}
          readOnly={readOnly}
          className={clsx(
            "w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 text-primary-700 focus:ring-primary-300",
            { "focus:ring-red-500 border-red-500 focus:border-red-500": errors[id] !== undefined },
          )}
        />
        <label htmlFor={id} className="block ml-2 text-sm font-medium text-gray-900 ">
          {label}
        </label>
      </div>
      <div className="mt-1">
        {helperText && <p className="mt-1 italic text-xs text-gray-400">{helperText}</p>}
        {errors[id] && (
          <div className="text-sm text-red-500 flex flex-row align-middle items-start gap-x-2">
            <ExclamationTriangleIcon className="h-4 w-4 my-auto" />
            <span className="">{errors[id]!.message?.toString()}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const RichTextInput = ({
  label,
  placeholder = "",
  helperText,
  id,
  type = "text",
  readOnly = false,
  validation,
  ...rest
}: InputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <Controller
        render={({ field }) => <WYSIWYGEditor value={field.value} onChange={field.onChange} readOnly={readOnly} />}
        name={id}
        control={control}
        {...rest}
      />

      <div className="mt-1">
        {helperText && <p className="mt-1 italic text-xs text-gray-400">{helperText}</p>}
        {errors[id] && (
          <div className="text-sm text-red-500 flex flex-row align-middle items-start gap-x-2">
            <ExclamationTriangleIcon className="h-4 w-4 my-auto" />
            <span className="">{errors[id]!.message?.toString()}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export type TextAreaInputProps = {
  label: string;
  id: string;
  placeholder?: string;
  helperText?: string;
  type?: string;
  readOnly?: boolean;
  validation?: RegisterOptions;
} & React.ComponentPropsWithoutRef<"textarea">;

export const TextAreaInput = ({
  label,
  placeholder = "",
  helperText,
  id,
  readOnly = false,
  validation,
  ...rest
}: TextAreaInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>
      <textarea
        {...register(id, validation)}
        {...rest}
        name={id}
        id={id}
        readOnly={readOnly}
        className={clsx(
          "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5",
          { "focus:ring-red-500 border-red-500 focus:border-red-500": errors[id] !== undefined },
          { "bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300": readOnly },
        )}
      />
      <div className="mt-1">
        {helperText && <p className="mt-1 italic text-xs text-gray-400">{helperText}</p>}
        {errors[id] && (
          <div className="text-sm text-red-500 flex flex-row align-middle items-start gap-x-2">
            <ExclamationTriangleIcon className="h-4 w-4 my-auto" />
            <span className="">{errors[id]!.message?.toString()}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export type SelectProps = {
  label: string;
  id: string;
  placeholder?: string;
  helperText?: string;
  type?: string;
  readOnly?: boolean;
  validation?: RegisterOptions;
  children: ReactNode;
} & React.ComponentPropsWithoutRef<"select">;

export const Select = ({
  label,
  id,
  placeholder,
  helperText,
  type,
  readOnly = false,
  validation,
  children,
  ...rest
}: SelectProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // Add disabled and selected attribute to option, will be used if readonly
  const readOnlyChildren = Children.map<ReactNode, ReactNode>(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as React.ReactElement<any>, {
        disabled: child.props.value !== rest?.defaultValue,
        selected: child.props.value === rest?.defaultValue,
      });
    }
  });

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-normal text-gray-700">
        {label}
      </label>
      <div className="relative mt-1">
        <select
          {...register(id, validation)}
          // defaultValue to value blank, will get overriden by ...rest if needed
          defaultValue=""
          {...rest}
          name={id}
          id={id}
          className={clsx(
            "block w-full rounded-md shadow-sm text-sm bg-gray-50",
            { "focus:ring-primary-500 border-gray-300 focus:border-primary-500": !readOnly && !errors[id] },
            { "bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300": readOnly },
            { "focus:ring-red-500 border-red-500 focus:border-red-500": errors[id] },
          )}
          aria-describedby={id}
        >
          {placeholder && (
            <>
              <option value="">{placeholder}</option>
            </>
          )}
          {readOnly ? readOnlyChildren : children}
        </select>

        {errors[id] && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationTriangleIcon className="text-xl text-red-500" />
          </div>
        )}
      </div>
      <div className="mt-1">
        {helperText && <p className="text-xs text-gray-500">{helperText}</p>}
        {errors[id] && <span className="text-sm text-red-500">{errors[id]!.message?.toString()}</span>}
      </div>
    </div>
  );
};

interface UploadedImage {
  imageName: string;
  imageUrl: string;
}
interface UploadImagesResponse {
  images: UploadedImage[];
}

const uploadImageRequest = async (url: string, { arg }: { arg: File }) => {
  let formData = new FormData();
  formData.append("file", arg);
  const response = await axiosInstance.post<UploadImagesResponse>(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const ImageUpload = ({
  label,
  placeholder = "",
  helperText,
  id,
  type = "text",
  readOnly = false,
  validation,
  ...rest
}: InputProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { trigger, isMutating } = useSWRMutation("/api/v2/upload/images", uploadImageRequest);

  const clearSelectedFile = () => {
    setSelectedFile(null);
    hiddenFileInput.current!.value = "";
    setValue(id, null, { shouldDirty: true });
  };

  const handleImageButtonClicked = () => {
    const isRemoveClick = selectedFile != null;
    if (isRemoveClick) {
      clearSelectedFile();
    } else {
      hiddenFileInput.current!.click();
    }
  };

  const onFileInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (!target.files) return;

    setSelectedFile(target.files[0]);
    const toastId = toast.loading("Uploading image...", {
      pauseOnHover: false,
    });
    try {
      const response = await trigger(target.files[0]);
      toast.update(toastId, { render: "Image uploaded!", type: "success", isLoading: false, autoClose: 3500 });
      setValue(id, response.images[0].imageUrl, { shouldDirty: true });
    } catch (e) {
      const errorResponse = handleAxiosError(e);
      toast.update(toastId, {
        render: errorResponse.errorDetails ?? errorResponse.error,
        type: "error",
        isLoading: false,
        autoClose: 3500,
      });
      clearSelectedFile();
    }
  };

  return (
    <div>
      <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <input type="file" ref={hiddenFileInput} onChange={onFileInputChange} className="hidden" />
      <div className="mt-2 flex items-center gap-x-3">
        <div className="mt-2 flex rounded-md shadow-sm flex-grow">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <PhotoIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              {...register(id, validation)}
              {...rest}
              type="text"
              name={id}
              id={id}
              readOnly
              placeholder="Select image to upload"
              className={clsx(
                "bg-gray-50 pl-10 border-gray-300 text-gray-400 text-sm rounded-l-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5",
                { "focus:ring-red-500 border-red-500 focus:border-red-500": errors[id] !== undefined },
                { "bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300": readOnly },
              )}
            />
          </div>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleImageButtonClicked}
            disabled={isMutating}
          >
            {selectedFile !== null ? (
              <>
                <XCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Remove
              </>
            ) : (
              <>
                <ArrowUpCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Upload
              </>
            )}
          </button>
        </div>
      </div>
      <div className="mt-1">
        {helperText && <p className="mt-1 italic text-xs text-gray-400">{helperText}</p>}
        {errors[id] && (
          <div className="text-sm text-red-500 flex flex-row align-middle items-start gap-x-2">
            <ExclamationTriangleIcon className="h-4 w-4 my-auto" />
            <span className="">{errors[id]!.message?.toString()}</span>
          </div>
        )}
      </div>
    </div>
  );
};
