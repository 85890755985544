import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { defaultGetFetcher } from "../../../../api/base";
import { Card } from "../../../../components/card";
import {
  ProgramInformationData,
  ProgramTaskData,
  useWebinarFormStore,
} from "../../../../store/webinarProgramCreate.store";
import useSWR from "swr";
import { useAdvisorsBaseDataAPI } from "../../../../api/advisorsApi";

interface WebinarProgramDetailsResponse {
  programInformation: ProgramInformationData;
  tasks: ProgramTaskData[];
}
const LoadingTaskList = () => (
  <div className="flex flex-col w-full gap-y-6 mb-6">
    <div className="uppercase text-center font-bold tracking-wider text-gray-900">Step 1</div>
    <ul role="list" className="divide-y divide-gray-100">
      <li className="flex flex-col gap-y-2 py-5">
        <div className="flex items-start gap-x-2">
          <p className="text-sm font-semibold leading-6 text-gray-900/80">Loading...</p>
        </div>
        <div className="w-full h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
        <div className="w-1/4 h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
        <div className="w-3/4 h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
      </li>
    </ul>
  </div>
);

const EditWebinarProgram = () => {
  const { webinarId } = useParams();
  const { setStepOne, setStepTwo } = useWebinarFormStore();
  const { data, isLoading: isWebinarDetailLoading } = useSWR<WebinarProgramDetailsResponse, Error>(
    `api/v2/admin/webinar-programs/${webinarId}`,
    (url: string) => defaultGetFetcher(url),
  );
  const { isLoading: isAdvisorsListLoading } = useAdvisorsBaseDataAPI();
  const isLoading = isWebinarDetailLoading && isAdvisorsListLoading;

  useEffect(() => {
    if (!data) return;
    setStepOne(data.programInformation);
    setStepTwo(data.tasks);
  }, [data, setStepOne, setStepTwo]);

  if (!isLoading && data) {
    return <Navigate to={`step-1`} />;
  }

  return (
    <div className="lg:h-full overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Edit webinar program</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full px-4 mb-2 border border-gray-100">
          <LoadingTaskList />
        </Card>
      </div>
    </div>
  );
};

export default EditWebinarProgram;
