import { usePaginationStore } from "../../store/pagePaginationStore";

export const TablePagination = () => {
  const updateCurrentPage = usePaginationStore((state) => state.updateCurrentPage);
  const paginationData = usePaginationStore((state) => state.paginationData);
  const currentPage = usePaginationStore((state) => state.currentPage);
  return (
    <nav
      className="flex md:flex-row flex-col md:gap-y-0 gap-y-4 items-center justify-between py-4"
      aria-label="Table navigation"
    >
      <span className="text-sm font-normal text-gray-500 ">
        Showing{" "}
        <span className="font-semibold text-gray-900 ">
          {paginationData.startIndex}-{paginationData.endIndex}
        </span>{" "}
        of <span className="font-semibold text-gray-900 ">{paginationData.total}</span>
      </span>
      <ul className="inline-flex -space-x-px text-sm h-8">
        <li>
          <button
            className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg enabled:hover:bg-gray-100 enabled:hover:text-gray-700 disabled:hover:no disabled:cursor-default"
            disabled={!paginationData.hasPrevious}
            onClick={() => updateCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
        </li>
        {paginationData.hasPrevious && (
          <li>
            <button
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
              onClick={() => updateCurrentPage(currentPage - 1)}
            >
              {paginationData.currentPage - 1}
            </button>
          </li>
        )}
        <li>
          <button className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700">
            {currentPage}
          </button>
        </li>
        {paginationData.hasNext && (
          <li>
            <button
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              onClick={() => updateCurrentPage(currentPage + 1)}
            >
              {currentPage + 1}
            </button>
          </li>
        )}
        <li>
          <button
            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg enabled:hover:bg-gray-100 enabled:hover:text-gray-700 disabled:cursor-default"
            disabled={!paginationData.hasNext}
            onClick={() => updateCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};
