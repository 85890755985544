import { CardWithHeading } from "../../../components/card";

const Dashboard = () => {
  return (
    <>
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>
      </header>
      <div className="mt-4">
        <CardWithHeading header={<h2>Welcome to Willow Admin</h2>}>
          <p>Here we will add more details in future.</p>
        </CardWithHeading>
      </div>
    </>
  );
};

export default Dashboard;
