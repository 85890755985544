import useSWRMutation from "swr/mutation";
import { IErrorResponse, axiosInstance } from "../api/base";
import useAppStore from "../store/app.store";

export interface ILoginResponse extends IErrorResponse {
  success: boolean;
  twoFactorAuth: string;
  userId: number;
  firstName: string;
}

export interface ILoginRequestData {
  email: string;
  password: string;
}

const loginRequest = async (url: string, { arg }: { arg: ILoginRequestData }) => {
  const result = await axiosInstance.post<ILoginResponse>(url, arg);
  if (result.data.error) {
    throw Error(result.data.errorDetails ?? result.data.error);
  }
  return result.data;
};

const logoutRequest = async (url: string) => await axiosInstance.post(url);

const useProvideAuth = () => {
  const { trigger: loginTrigger, isMutating: isLoginMutating } = useSWRMutation("api-login", loginRequest, {
    throwOnError: true,
  });
  const { trigger: logoutTrigger, isMutating: isLogoutMutating } = useSWRMutation("api-logout", logoutRequest);
  const { setIsLoggedIn, setAuthData } = useAppStore.getState();

  const login = async (email: string, password: string) => {
    const result = await loginTrigger({ email: email, password: password });
    setIsLoggedIn(true);
    setAuthData({ id: result.userId, firstName: result.firstName });
    return result;
  };

  const logout = async () => {
    await logoutTrigger();
    setIsLoggedIn(false);
    setAuthData(null);
    return true;
  };

  return {
    login,
    logout,
    isLoading: isLoginMutating || isLogoutMutating,
  };
};

export default useProvideAuth;
