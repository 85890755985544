import useSWRImmutable from "swr/immutable";
import { z } from "zod";
import { defaultGetFetcher } from "./base";
import { useEffect, useState } from "react";

export const AdvisorBaseDataSchema = z.object({
  id: z.number(),
  fullName: z.string(),
});
export const AdvisorResponseSchema = z.object({
  advisors: AdvisorBaseDataSchema.array(),
});

export type AdvisorBaseData = z.infer<typeof AdvisorBaseDataSchema>;
export type AdvisorBaseResponse = z.infer<typeof AdvisorResponseSchema>;

export const useAdvisorsBaseDataAPI = () => {
  const { data, isLoading } = useSWRImmutable<string, Error>(
    "/api/v2/admin/advisors",
    (url: string) => defaultGetFetcher(url),
    { revalidateOnMount: true },
  );
  const [advisors, setAdvisors] = useState<AdvisorBaseData[]>([]);

  useEffect(() => {
    if (!data) return;
    const response: AdvisorBaseResponse = AdvisorResponseSchema.parse(data);
    setAdvisors(response.advisors);
  }, [data]);

  return {
    advisors,
    isLoading,
  };
};
