import { create } from "zustand";
import * as zod from "zod";
import { v4 as uuid4 } from "uuid";

export const programInformationSchema = zod.object({
  id: zod.preprocess((x) => (x ? (String(x) === "" ? null : Number(x)) : null), zod.number().nullish()),
  title: zod.string(),
  description: zod.string(),
  advisorId: zod.preprocess((x) => (x ? (String(x) === "" ? null : Number(x)) : null), zod.number().nullish()),
  isAssetManagementProgram: zod.boolean(),
  isPromotionalProgram: zod.boolean(),
  imageUrl: zod.union([zod.string().url().nullish().default(null), zod.literal("")]),
});

export const programTaskSchema = zod.object({
  id: zod.preprocess((x) => (x ? (String(x) === "" ? null : Number(x)) : null), zod.number().nullish()),
  title: zod.string({ required_error: "Title is required" }).default(""),
  description: zod.string({ required_error: "Description is required" }).default(""),
  imageUrl: zod.union([zod.string().url().nullish().default(null), zod.literal("")]),
  videoUrl: zod.union([zod.string().url().nullish().default(null), zod.literal("")]),
  isPracticeSelfServe: zod.boolean().nullish(),

  order: zod.coerce.number().default(0),
  ceCredits: zod.coerce.number().default(0),
  tempId: zod.string().uuid().default(uuid4),

  surveyId: zod.preprocess((x) => (x ? (String(x) === "" ? null : Number(x)) : null), zod.number().nullish()),
  surveyTitle: zod.union([zod.string().nullish().default(null), zod.literal("")]),
  surveyUrl: zod.union([zod.string().url().nullish().default(null), zod.literal("")]),
  isSurveyAttestation: zod.boolean().nullish(),
});

export type ProgramInformationData = zod.infer<typeof programInformationSchema>;
export type ProgramTaskData = zod.infer<typeof programTaskSchema>;

interface IProgramFormState {
  stepOne: ProgramInformationData | null;
  stepTwo: ProgramTaskData[];
  setStepOne: (data: ProgramInformationData) => void;
  setStepTwo: (data: ProgramTaskData[]) => void;
  upsertStepTwoTask: (data: ProgramTaskData) => void;
  clearData: () => void;
}

export const useWebinarFormStore = create<IProgramFormState>()((set) => ({
  stepOne: null,
  stepTwo: [],
  setStepOne: (data) => set((state) => ({ ...state, stepOne: data })),
  setStepTwo: (data) => set((state) => ({ ...state, stepTwo: data })),
  upsertStepTwoTask: (data) =>
    set((state) => {
      if (data.id) {
        const index = state.stepTwo.findIndex((t) => t.id === data.id);
        let stepTwoTasks = state.stepTwo;
        stepTwoTasks[index] = data;
        return { ...state, stepTwo: stepTwoTasks };
      } else if (data.tempId) {
        const index = state.stepTwo.findIndex((t) => t.tempId === data.tempId);
        if (index > -1) {
          let stepTwoTasks = state.stepTwo;
          stepTwoTasks[index] = data;
          return { ...state, stepTwo: stepTwoTasks };
        }
        return { ...state, stepTwo: [...state.stepTwo, data] };
      } else {
        data.tempId = uuid4();
        return { ...state, stepTwo: [...state.stepTwo, data] };
      }
    }),
  clearData: () => set((state) => ({ ...state, stepOne: null, stepTwo: [] })),
}));
