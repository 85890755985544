import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import willowLogoUrl from "@assets/willowlogotransparent.png";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/app.store";
import useProvideAuth from "../../hooks/useAuth";
import { useState } from "react";

interface ILoginFormInput {
  email: string;
  password: string;
}

export default function SignIn() {
  const [error, setError] = useState<string | undefined>(undefined);
  const { login, isLoading } = useProvideAuth();
  const { isLoggedIn } = useAppStore.getState();

  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const loginMessage = searchParams.get("message");

  const { register, handleSubmit, formState } = useForm<ILoginFormInput>({ mode: "onChange" });

  const onSubmit: SubmitHandler<ILoginFormInput> = async (formData) => {
    try {
      await login(formData.email, formData.password);
      navigate("/", { relative: "path" });
    } catch (err) {
      if (err instanceof Error) setError(err.message);
    }
  };

  if (isLoggedIn) return <Navigate to="/" replace />;

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={willowLogoUrl} alt="Your Company" />
          <h2 className="mt-10 text-center text-2xl font-serif font-semibold leading-9 text-gray-600">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 p-8 rounded-3xl shadow-xl sm:mx-auto sm:w-full sm:max-w-sm bg-secondary-500">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="email"
              autoComplete="email"
              placeholder="Email address"
              disabled={isLoading}
              {...register("email", { required: true })}
              className="block w-full rounded-xl border-0 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-gray-300 disabled:bg-gray-300 disabled:ring-0"
            />

            <input
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              disabled={isLoading}
              {...register("password", { required: true })}
              className="block w-full rounded-xl border-0 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-gray-300 disabled:bg-gray-300 disabled:ring-0"
            />

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <Link to="../forgot-password" className="underline text-gray-50">
                  Forgot password?
                </Link>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isLoading}
                className={clsx(
                  "flex justify-center rounded-full px-10 py-2",
                  "text-sm font-semibold leading-6 shadow  disabled:bg-gray-400",
                  {
                    " bg-primary-300 text-white": formState.isValid,
                    "bg-gray-300 text-gray-500": !formState.isValid,
                  },
                )}
              >
                {isLoading ? "Loading..." : "Sign in"}
              </button>
            </div>
          </form>
        </div>
        {loginMessage && <p className="mt-10 text-center text-gray-500 italic">{loginMessage}</p>}
        {error && <p className="mt-10 text-center text-red-800 italic">{error}</p>}
      </div>
    </>
  );
}
