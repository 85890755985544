import { Combobox } from "@headlessui/react";
import { ChevronUpDownIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

export type SelectOption = {
  id: string | number;
  value: string | number;
  displayValue: string | number;
};

export type SelectProps = {
  label: string;
  id: string;
  helperText?: string;
  readOnly?: boolean;
  options: SelectOption[];
} & React.ComponentPropsWithoutRef<"input">;

export const SelectCombo = ({
  label,
  id,
  placeholder,
  helperText,
  readOnly = false,
  options,
  ...rest
}: SelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const filteredEntries =
    searchQuery === ""
      ? options
      : options.filter((o) => o.displayValue.toString().toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-normal text-gray-700">
        {label}
      </label>
      <div className="relative mt-1">
        <Controller
          name={id}
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Combobox
              as="div"
              {...field}
              className={clsx(
                "block w-full rounded-md shadow-sm text-sm bg-gray-50",
                { "focus:ring-primary-500 border-gray-300 focus:border-primary-500": !readOnly && !errors[id] },
                { "bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300": readOnly },
                { "focus:ring-red-500 border-red-500 focus:border-red-500": errors[id] },
              )}
            >
              <div className="relative">
                <Combobox.Input
                  className={clsx(
                    "block w-full rounded-md shadow-sm text-sm bg-gray-50",
                    { "focus:ring-primary-500 border-gray-300 focus:border-primary-500": !readOnly && !errors[id] },
                    { "bg-gray-100 focus:ring-0 cursor-not-allowed border-gray-300 focus:border-gray-300": readOnly },
                    { "focus:ring-red-500 border-red-500 focus:border-red-500": errors[id] },
                  )}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  displayValue={(entry: string | number) =>
                    options.find((o) => o.value === entry)?.displayValue.toString() ?? searchQuery
                  }
                  autoComplete="off"
                  {...rest}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredEntries.length > 0 &&
                    filteredEntries.map((entry) => (
                      <Combobox.Option
                        key={entry.id}
                        value={entry.value}
                        className={({ active }) =>
                          clsx(
                            "relative cursor-default select-none py-2 pl-3 pr-9",
                            active ? "bg-primary-600 text-white" : "text-gray-900",
                          )
                        }
                      >
                        {({ selected }) => (
                          <>
                            <span className={clsx("block truncate", selected && "font-semibold")}>
                              {entry.displayValue}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                </Combobox.Options>
              </div>
            </Combobox>
          )}
        />

        {errors[id] && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationTriangleIcon className="text-xl text-red-500" />
          </div>
        )}
      </div>
      <div className="mt-1">
        {helperText && <p className="text-xs text-gray-500">{helperText}</p>}
        {errors[id] && <span className="text-sm text-red-500">{errors[id]!.message?.toString()}</span>}
      </div>
    </div>
  );
};
