import { create } from "zustand";

interface AuthData {
  id: number;
  firstName: string;
}

interface AppState {
  isLoggedIn: boolean;
  loggedInUser: AuthData | null;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setAuthData: (authData: AuthData | null) => void;
}

const useAppStore = create<AppState>()((set) => ({
  isLoggedIn: true,
  loggedInUser: null,
  setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn: isLoggedIn }),
  setAuthData: (authData: AuthData | null) => set({ loggedInUser: authData }),
}));

export default useAppStore;
