import { useState } from "react";
import { Link } from "react-router-dom";
import * as zod from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import useSWRMutation from "swr/mutation";
import clsx from "clsx";

import willowLogoUrl from "@assets/willowlogotransparent.png";
import { axiosInstance } from "../../api/base";

async function sendPasswordResetRequest(url: string, { arg }: { arg: { email: string } }) {
  return await axiosInstance.post(url, arg);
}

const validationSchema = zod.object({
  email: zod.string().email("Email is invalid"),
});

interface IPasswordResetFormInput extends zod.infer<typeof validationSchema> {}

export default function PasswordReset() {
  const [error, setErrorMessage] = useState<string | undefined>(undefined);
  const [message, setSuccessMessage] = useState<string | undefined>(undefined);
  const { trigger, isMutating } = useSWRMutation("api-password-reset", sendPasswordResetRequest);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<IPasswordResetFormInput>({
    resolver: zodResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<IPasswordResetFormInput> = async (data) => {
    try {
      await trigger(data);
      setSuccessMessage(
        "We've sent password reset to your email. Please follow steps from email to reset your password!",
      );
      reset();
    } catch (e) {
      setErrorMessage("There was unexpected error. Error has been reported. Please try again in couple of minutes!");
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={willowLogoUrl} alt="Your Company" />
          <h2 className="mt-10 text-center text-2xl font-serif font-semibold leading-9 text-gray-600">
            Forgot your password
          </h2>
        </div>

        <div className="mt-10 p-8 rounded-3xl shadow-xl sm:mx-auto sm:w-full sm:max-w-sm bg-secondary-500">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <p className="text-gray-50 text-sm text-center">
              Enter the email address you registered with to be sent a password reset:
            </p>
            <div>
              <input
                type="email"
                autoComplete="email"
                placeholder="Email address"
                required
                {...register("email")}
                disabled={isMutating}
                className={clsx(
                  "block w-full rounded-xl border-0 py-3 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus:ring-gray-300",
                  " disabled:bg-gray-400 disabled:ring-0",
                  {
                    "ring-1 ring-red-200": errors.email !== undefined,
                  },
                )}
              />
              {errors.email !== undefined && (
                <label className="text-red-200 text-xs italic">{errors.email.message}</label>
              )}
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isMutating}
                className={clsx(
                  "flex justify-center rounded-full px-12 py-1.5",
                  "text-sm font-semibold leading-6 shadow disabled:bg-gray-400",
                  {
                    " bg-primary-300 text-white": isValid,
                    "bg-gray-300 text-gray-500": !isValid,
                  },
                )}
              >
                Send password reset
              </button>
            </div>

            <div className="flex items-center justify-center text-sm">
              <Link to="../login" className="underline text-gray-50">
                Cancel
              </Link>
            </div>
          </form>
        </div>
        {message && <p className="mt-10 text-center text-gray-500 italic">{message}</p>}
        {error && <p className="mt-10 text-center text-red-800 italic">{error}</p>}
      </div>
    </>
  );
}
