import { FormProvider, useForm } from "react-hook-form";
import { Card } from "../../../../components/card";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckboxInput, ImageUpload, Input, TextAreaInput, Select } from "../../../../components/formInputs";
import { ProgramTaskData, programTaskSchema, useWebinarFormStore } from "../../../../store/webinarProgramCreate.store";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { v4 as uuid4 } from "uuid";
import { useSurveysAPI } from "../../../../api/surveyApi";
import { TagPill } from "../../../../components/webinar/tagPill";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

const ProgramTask = ({
  task,
  taskOrder,
  onDeleteButtonClicked,
}: {
  task: ProgramTaskData;
  taskOrder: number;
  onDeleteButtonClicked: (taskId: number) => void;
}) => {
  let tagPills = [];
  if (task.surveyId || (task.surveyUrl && task.surveyTitle)) tagPills.push("Survey");
  if (task.videoUrl) tagPills.push("Video");

  return (
    <li className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex items-start gap-x-2">
          <p className="text-sm font-bold leading-6 text-gray-500">#{taskOrder + 1}: </p>
          <p className="text-sm font-semibold leading-6 text-gray-900">{task.title}</p>
          {tagPills.map((tag, id) => (
            <TagPill key={id} tag={tag} />
          ))}
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p className="whitespace-nowrap truncate">{task.description}</p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-2">
        <button
          disabled
          // onClick={() => onEditButtonClicked(taskOrder)}
          className="hidden rounded-md bg-white disabled:bg-gray-100 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm enabled:ring-1 ring-inset ring-gray-300 enabled:hover:bg-gray-50 sm:block"
        >
          Edit task<span className="sr-only">, {task.title}</span>
        </button>
        <button
          className="hidden rounded-md bg-red-100 px-2.5 py-2 text-sm text-red-900 shadow-sm ring-1 ring-inset ring-red-200 hover:bg-red-200 sm:block"
          onClick={() => onDeleteButtonClicked(taskOrder)}
        >
          <TrashIcon className="h-4 w-4 stroke-2" />
          <span className="sr-only">Delete task, {task.title}</span>
        </button>
      </div>
    </li>
  );
};

const EmptyTaskList = () => (
  <>
    <div className="flex gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex items-start gap-x-2">
          <p className="text-sm font-semibold leading-6 text-gray-900">Task list is empty</p>
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p className="whitespace-nowrap truncate">To create program, please add some of the tasks</p>
        </div>
      </div>
    </div>
  </>
);

const LoadingTaskList = () => (
  <ul role="list" className="divide-y divide-gray-100">
    <li className="flex flex-col gap-y-2 py-5">
      <div className="flex items-start gap-x-2">
        <p className="text-sm font-semibold leading-6 text-gray-900/80">Loading...</p>
      </div>
      <div className="w-full h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
      <div className="w-1/4 h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
      <div className="w-3/4 h-3 bg-gray-200/80 animate-pulse rounded-lg"></div>
    </li>
  </ul>
);

const CreateWebinarProgramStep2 = () => {
  const { stepTwo: stepTwoTasks, setStepTwo } = useWebinarFormStore();
  const [isTaskFormVisible, setIsTaskFormVisible] = useState(false);
  const navigate = useNavigate();
  const { surveys, isLoading } = useSurveysAPI();

  const formMethods = useForm<ProgramTaskData>({
    mode: "onTouched",
    resolver: zodResolver(programTaskSchema),
    defaultValues: () => Promise.resolve(programTaskSchema.parse({ order: stepTwoTasks.length + 1 })),
  });

  const onTaskFormCompleted = (data: ProgramTaskData) => {
    data.tempId = uuid4();
    setStepTwo([...stepTwoTasks, data]);
    setIsTaskFormVisible(false);
    formMethods.reset(programTaskSchema.parse({ order: stepTwoTasks.length + 2 }));
  };

  const deleteTask = (taskId: number) => {
    const deletedTask = stepTwoTasks[taskId];
    if (confirm(`Are you sure you want to delete task - ${deletedTask.title}?`)) {
      setStepTwo(stepTwoTasks.filter((_, tid) => taskId !== tid));
    }
  };

  const completeStepTwo = () => {
    navigate("/programs/create/step-3");
  };

  return (
    <div className="lg:h-full pr-2 overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Create webinar program</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full px-4 mb-2 border border-gray-100">
          <h2 className="uppercase text-center font-bold tracking-wider text-gray-900">Step 2 - Tasks</h2>
          {isTaskFormVisible ? (
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit(
                  (d) => onTaskFormCompleted(d),
                  (d) => console.log(d),
                )}
                className="flex flex-col w-full gap-y-6 mb-6"
              >
                <div className="uppercase text-center font-bold tracking-wider text-gray-900">Add task</div>
                <Input label="Title" id="title" />
                <TextAreaInput label="Description" id="description" />
                <CheckboxInput
                  label="Is Practice Self Serve Course"
                  id="isPracticeSelfServe"
                  helperText="Is practice management self-serve course!"
                />
                <Input label="CE Credits" id="ceCredits" type="number" min={0} />
                <Input label="Task order" id="order" type="number" min={0} />
                <ImageUpload label="Task Image" id="imageUrl" />
                <Input label="Video URL" id="videoUrl" />
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                    <Tab
                      className={({ selected }) =>
                        clsx(
                          "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                          "ring-white/60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2",
                          selected
                            ? "bg-white text-primary-700 shadow"
                            : "text-gray-700 hover:bg-white/[0.12] hover:text-white",
                        )
                      }
                    >
                      Select existing survey
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        clsx(
                          "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                          "ring-white/60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2",
                          selected
                            ? "bg-white text-primary-700 shadow"
                            : "text-gray-700 hover:bg-white/[0.12] hover:text-white",
                        )
                      }
                    >
                      Create new survey
                    </Tab>
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <Select id="surveyId" label="Survey" placeholder=" ">
                        {surveys.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.title}
                          </option>
                        ))}
                      </Select>
                    </Tab.Panel>
                    <Tab.Panel>
                      <div className="flex flex-col w-full gap-y-6 mb-6">
                        <Input label="Survey Title" id="surveyTitle" />
                        <Input label="Survey Typeform URL" id="surveyUrl" />
                        <CheckboxInput
                          label="Is Survey Attestation"
                          id="isSurveyAttestation"
                          helperText="Some surveys are used as attestation of advisor. Check this if it is going to be used that way."
                        />
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>

                <div className="flex justify-between">
                  <button
                    onClick={() => setIsTaskFormVisible((value) => !value)}
                    className="text-gray-900 bg-gray-100 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Add task
                  </button>
                </div>
              </form>
            </FormProvider>
          ) : (
            <></>
          )}
          {!isTaskFormVisible ? (
            <div className="w-full px-4 py-4 flex flex-col gap-y-4">
              {isLoading && <LoadingTaskList />}
              {!isLoading && stepTwoTasks.length === 0 && <EmptyTaskList />}
              {!isLoading && stepTwoTasks.length > 0 && (
                <ul role="list" className="divide-y divide-gray-100">
                  {stepTwoTasks.map((t, id) => (
                    <ProgramTask key={id} task={t} taskOrder={id} onDeleteButtonClicked={deleteTask} />
                  ))}
                </ul>
              )}
              <div className="flex flex-col gap-y-2 sm:flex-row sm:justify-between">
                <Link
                  to="/programs/create/step-1"
                  className="text-gray-900 border border-gray-300 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Back
                </Link>

                <button
                  className="rounded-md flex flex-row justify-center items-center gap-x-1 enabled:bg-green-50 disabled:bg-gray-100 px-2.5 py-2.5 text-sm font-semibold enabled:text-green-900 shadow-sm enabled:ring-1 ring-inset enabled:ring-green-200 enabled:hover:bg-green-500/20"
                  onClick={() => setIsTaskFormVisible((value) => !value)}
                  disabled={isLoading}
                >
                  <PlusIcon className="h-4 w-4 stroke-2" /> Add task
                </button>
                <button
                  onClick={completeStepTwo}
                  disabled={stepTwoTasks.length === 0}
                  className="text-white bg-primary-700 disabled:bg-gray-300 disabled:text-gray-600 enabled:hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          <p className="text-center w-full text-gray-400 text-xs tracking-wide">Step 2/3</p>
        </Card>
      </div>
    </div>
  );
};

export default CreateWebinarProgramStep2;
