import useSWRImmutable from "swr/immutable";
import { z } from "zod";
import { defaultGetFetcher } from "./base";
import { useEffect, useState } from "react";

export const SurveysSchema = z.object({
  id: z.number(),
  title: z.string().optional(),
});
export const SurveysResponseSchema = z.object({
  surveys: SurveysSchema.array(),
});

export type Survey = z.infer<typeof SurveysSchema>;
export type SurveyResponse = z.infer<typeof SurveysResponseSchema>;

export const useSurveysAPI = () => {
  const { data, isLoading } = useSWRImmutable<string, Error>(
    "/api/v2/admin/surveys",
    (url: string) => defaultGetFetcher(url),
    { revalidateOnMount: true },
  );
  const [surveys, setSurveys] = useState<Survey[]>([]);

  useEffect(() => {
    if (!data) return;
    const response: SurveyResponse = SurveysResponseSchema.parse(data);
    setSurveys(response.surveys);
  }, [data]);

  return {
    surveys: surveys,
    isLoading,
  };
};
