import useSWRImmutable from "swr/immutable";
import { axiosInstance } from "./api/base";
import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import logoIcon from "@assets/logoIcon.png";

interface AuthResponse {}

const fetcher = (url: string) => axiosInstance.get(url).then((r) => r.data);

function App(props: React.PropsWithChildren) {
  const [shouldFetch, setShouldFetch] = useState(true);
  const { data, error, isLoading } = useSWRImmutable<AuthResponse, Error>(shouldFetch ? "/apiv1/auth" : null, fetcher);

  useEffect(() => {
    if (data || error) setShouldFetch(false);
  }, [data, error]);

  return (
    <>
      <Transition
        show={isLoading}
        enter="transition-all duration-500"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <div className="flex justify-center items-center h-screen flex-col gap-y-4">
          <div className="flex w-full justify-center">
            <img src={logoIcon} className="h-16 w-auto lg:hidden animate-spin-slow" />
          </div>
          <h1 className="text-3xl text-gray-500 animate-pulse">Loading...</h1>
        </div>
      </Transition>
      {isLoading ? null : props.children}
    </>
  );
}

export default App;
