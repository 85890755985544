import { useWebinarFormStore } from "../../../../store/webinarProgramCreate.store";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useAdvisorsBaseDataAPI } from "../../../../api/advisorsApi";

const CreateWebinarProgram = () => {
  const { clearData } = useWebinarFormStore();
  const { isLoading } = useAdvisorsBaseDataAPI();

  useEffect(() => {
    //clear step one data on mount
    clearData();
  }, []);

  return isLoading ? <></> : <Navigate to="step-1" />;
};

export default CreateWebinarProgram;
