import { Link, useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { Card } from "../../../../components/card";
import {
  ProgramInformationData,
  ProgramTaskData,
  useWebinarFormStore,
} from "../../../../store/webinarProgramCreate.store";
import WYSIWYGEditor from "../../../../components/richTextEditor";
import { IErrorResponse, axiosInstance, handleAxiosError } from "../../../../api/base";
import { toast } from "react-toastify";
import { useAdvisorsBaseDataAPI } from "../../../../api/advisorsApi";

interface CreateWebinarProgramRequestData {
  programInformation: ProgramInformationData;
  tasks: ProgramTaskData[];
}

interface CreateWebinarProgramResponse extends IErrorResponse {
  id: number;
  title: string;
}

const createWebinarProgramRequest = async (url: string, { arg }: { arg: CreateWebinarProgramRequestData }) => {
  const response = await axiosInstance.post<CreateWebinarProgramResponse>(url, arg);
  return response.data;
};

const CreateWebinarProgramStep3 = () => {
  const { stepOne, stepTwo, clearData } = useWebinarFormStore();
  const { advisors } = useAdvisorsBaseDataAPI();
  const { trigger, isMutating, error } = useSWRMutation(
    "/api/v2/admin/webinar-programs/create-training",
    createWebinarProgramRequest,
  );
  const navigate = useNavigate();

  const onSaveButtonClicked = async () => {
    const toastId = toast.loading("Saving webinar program...", {
      pauseOnHover: false,
    });
    try {
      await trigger({
        programInformation: stepOne!,
        tasks: stepTwo,
      });
      toast.update(toastId, { render: "Webinar program created!", type: "success", isLoading: false, autoClose: 3500 });
      clearData();
      navigate("/programs");
      //navigate to programs
    } catch (e) {
      const errorResponse = handleAxiosError(error);
      toast.update(toastId, {
        render: errorResponse.errorDetails ?? errorResponse.error,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  return (
    <div className="lg:h-full pr-2 overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Create webinar program</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full px-4 mb-2 border border-gray-100">
          <h2 className="uppercase text-center font-bold tracking-wider text-gray-900">Step 3 - Review</h2>
          <div>
            <div className="px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Webinar program review</h3>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Title</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{stepOne?.title}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <WYSIWYGEditor value={stepOne?.description ?? ""} readOnly onChange={() => {}} />
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Advisor</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {advisors.find((a) => a.id === stepOne?.advisorId)?.fullName}
                  </dd>
                </div>
                {stepOne?.imageUrl && (
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Image</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <a href={stepOne?.imageUrl} className=" text-blue-500">
                        {stepOne?.imageUrl}
                      </a>
                    </dd>
                  </div>
                )}
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Flags</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <div className="flex flex-row justify-items-center">
                      <div className="w-1/2">Is Asset Management Program:</div>
                      <div className="w-1/2 text-left font-bold">{String(stepOne?.isAssetManagementProgram)}</div>
                    </div>
                    <div className="flex flex-row justify-items-center">
                      <div className="w-1/2">Is Promotional Program:</div>
                      <div className="w-1/2 text-left font-bold">{String(stepOne?.isPromotionalProgram)}</div>
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Tasks</h3>
                </div>
                {stepTwo.map((task, taskOrder) => (
                  <div key={task.tempId} className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900 truncate">
                      <span className="font-bold">#{taskOrder + 1}:</span> {task.title}
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <dl className="divide-y divide-gray-100">
                        <div className="py-2 sm:grid grid-cols-5 sm:gap-4">
                          <dt>Title</dt>
                          <dd className="col-span-4 truncate">{task.title}</dd>
                        </div>
                        <div className="py-2 sm:grid grid-cols-5 sm:gap-4">
                          <dt>Description</dt>
                          <dd className="col-span-4">{task.description}</dd>
                        </div>
                        {task.imageUrl && (
                          <div className="py-2 sm:grid grid-cols-5 sm:gap-4">
                            <dt>Image</dt>
                            <dd className="col-span-4">
                              <a href={task.imageUrl} className=" text-blue-500">
                                {task.imageUrl}
                              </a>
                            </dd>
                          </div>
                        )}
                        {task.videoUrl && (
                          <div className="py-2 sm:grid grid-cols-5 sm:gap-4">
                            <dt>Video</dt>
                            <dd className="col-span-4">
                              <a href={task.videoUrl} className=" text-blue-500">
                                {task.videoUrl}
                              </a>
                            </dd>
                          </div>
                        )}
                        {task.surveyId && (
                          <div className="py-2 sm:grid grid-cols-5 sm:gap-4">
                            <dt>Survey</dt>
                            <dd className="col-span-4">({task.surveyId}) Survey</dd>
                          </div>
                        )}
                        {task.surveyTitle && task.surveyUrl && (
                          <div className="py-2 sm:grid grid-cols-5 sm:gap-4">
                            <dt>New survey</dt>
                            <dd className="col-span-4">
                              <div className="flex flex-row justify-items-center">
                                <div className="w-1/2">Survey title:</div>
                                <div className="w-1/2 text-left font-bold">{String(task.surveyTitle)}</div>
                              </div>
                              <div className="flex flex-row justify-items-center">
                                <div className="w-1/2">Survey URL:</div>
                                <div className="w-1/2 text-left font-bold truncate">{String(task.surveyUrl)}</div>
                              </div>
                              <div className="flex flex-row justify-items-center">
                                <div className="w-1/2">Is attestation survey:</div>
                                <div className="w-1/2 text-left font-bold">{String(task.isSurveyAttestation)}</div>
                              </div>
                            </dd>
                          </div>
                        )}
                        <div className="py-2 sm:grid grid-cols-5 sm:gap-4">
                          <dt>CE Credits</dt>
                          <dd className="col-span-4">{task.ceCredits}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">Flags</dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <div className="flex flex-row justify-items-center">
                              <div className="w-1/2">Is Practice self serve content:</div>
                              <div className="w-1/2 text-left font-bold">{String(task?.isPracticeSelfServe)}</div>
                            </div>
                          </dd>
                        </div>
                      </dl>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex justify-between">
            <Link
              to="/programs/create/step-2"
              className="text-gray-900 border border-gray-300 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Back
            </Link>

            <button
              disabled={isMutating}
              onClick={onSaveButtonClicked}
              className="text-white bg-primary-700 disabled:bg-gray-300 disabled:text-gray-600 enabled:hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              {isMutating ? "Saving..." : "Save"}
            </button>
          </div>
          <p className="text-center w-full text-gray-400 text-xs tracking-wide">Step 3/3</p>
        </Card>
      </div>
    </div>
  );
};
export default CreateWebinarProgramStep3;
