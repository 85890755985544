import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useProvideAuth from "../../hooks/useAuth";

export default function SignOut() {
  const navigate = useNavigate();
  const { logout } = useProvideAuth();

  useEffect(() => {
    const logoutAction = async () => {
      try {
        await logout();
        navigate("/");
      } catch (err) {
        if (err instanceof Error) console.log(err);
      }
    };
    logoutAction();
  }, []);

  return <>Logging out...</>;
}
