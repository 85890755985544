import clsx from "clsx";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const fromStringToEditorState = (value?: string) => {
  const defaultValue = value ? value : "";
  const blocksFromHtml = htmlToDraft(defaultValue);
  const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
  return EditorState.createWithContent(contentState);
};

const WYSIWYGEditor = ({
  value,
  onChange,
  readOnly,
}: {
  value: string;
  onChange: (val: string) => void;
  readOnly: boolean;
}) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const newEditorState = fromStringToEditorState(value);
      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = (editorState: EditorState) => {
    setUpdated(true);
    setEditorState(editorState);
    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <React.Fragment>
      <div className="editor rounded-lg px-1 py-1 border border-gray-300 bg-gray-50">
        <Editor
          spellCheck
          readOnly={readOnly}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbarClassName={clsx("rounded-lg ", { hidden: readOnly })}
          editorClassName="bg-white non"
          toolbar={{
            options: readOnly ? [] : ["inline", "list", "history"],
            inline: {
              options: ["bold", "italic", "underline"],
              bold: { className: "px-1 py-1 rounded" },
              italic: { className: "px-1 py-1 rounded" },
              underline: { className: "px-1 py-1 rounded" },
            },
            // blockType: { className: "px-1 py-1 rounded-wide", dropdownClassName: "demo-dropdown-custom" },
            // fontSize: { className: "px-1 py-1 rounded-medium" },
            list: {
              unordered: { className: "px-1 py-1 rounded" },
              ordered: { className: "px-1 py-1 rounded" },
              indent: { className: "px-1 py-1 rounded" },
              outdent: { className: "px-1 py-1 rounded" },
            },
            // textAlign: {
            //   left: { className: "px-1 py-1 rounded" },
            //   center: { className: "px-1 py-1 rounded" },
            //   right: { className: "px-1 py-1 rounded" },
            //   justify: { className: "px-1 py-1 rounded" },
            // },
            // fontFamily: { className: "px-1 py-1 rounded-wide", dropdownClassName: "demo-dropdown-custom" },
            // colorPicker: { className: "px-1 py-1 rounded", popupClassName: "demo-popup-custom" },
            history: {
              undo: { className: "px-1 py-1 rounded" },
              redo: { className: "px-1 py-1 rounded" },
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default WYSIWYGEditor;
