import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../../../components/card";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ProgramInformationData,
  programInformationSchema,
  useWebinarFormStore,
} from "../../../../store/webinarProgramCreate.store";
import { CheckboxInput, ImageUpload, Input, RichTextInput } from "../../../../components/formInputs";
import { useAdvisorsBaseDataAPI } from "../../../../api/advisorsApi";
import { SelectCombo } from "../../../../components/forms/select";

const EditWebinarProgramStep1 = () => {
  const { webinarId } = useParams();
  const navigate = useNavigate();
  const { stepOne, setStepOne, clearData } = useWebinarFormStore();
  const { advisors, isLoading } = useAdvisorsBaseDataAPI();
  const formMethods = useForm({
    mode: "onTouched",
    resolver: zodResolver(programInformationSchema),
    defaultValues: stepOne!,
  });

  const onSubmit = (data: ProgramInformationData) => {
    setStepOne(data);
    navigate(`/programs/edit/${webinarId}/step-2`);
  };

  const onCancel = () => {
    clearData();
    navigate(`/programs`);
  };

  return (
    <div className="lg:h-full overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Edit webinar program</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full px-4 mb-2 border border-gray-100">
          <h2 className="uppercase text-center font-bold tracking-wider text-gray-900">Step 1</h2>
          {!isLoading && (
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col w-full gap-y-6 mb-6">
                <Input label="Id" id="id" readOnly />
                <Input label="Title" id="title" />
                <RichTextInput label="Description" id="description" />
                <SelectCombo
                  id="advisorId"
                  label="Advisor"
                  placeholder=" "
                  options={advisors.map((a) => ({ id: a.id, value: a.id, displayValue: a.fullName }))}
                />
                <ImageUpload label="Program Image" id="imageUrl" />
                <CheckboxInput
                  label="Is Asset Management Program"
                  id="isAssetManagementProgram"
                  helperText="How can I help with this flag? This is weird!"
                />
                <CheckboxInput
                  label="Is Promotional Program"
                  id="isPromotionalProgram"
                  helperText="Check if the program is on-demand. Keep unchecked if the program is a live event."
                />
                <div className="flex justify-between">
                  <button
                    onClick={onCancel}
                    className="text-gray-900 border border-gray-300 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-priamry-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Next
                  </button>
                </div>
              </form>
            </FormProvider>
          )}

          <p className="text-center w-full text-gray-400 text-xs tracking-wide">Step 1/3</p>
        </Card>
      </div>
    </div>
  );
};

export default EditWebinarProgramStep1;
