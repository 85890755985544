export const camelCaseToNormal = (str: string) => {
  /*
    Convert camel case string to normal string
    example:
      firstName -> First Name
      lastName -> Last Name
      signUpDate -> Sign Up Date
      legalConfirmationDate -> Legal Confirmation Date
      additionalProfileInformation -> Additional Profile Information
    */
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
};
