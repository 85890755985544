import transparentLogo from "@assets/logotransparentwhite.png";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon, Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";
import { sidebarNavigation, userNavigation } from "./navigationLinks";
import logoTransparentWhite from "../../assets/logotransparentwhite.png";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarClose: () => void;
}

const Sidebar = (props: SidebarProps) => {
  return (
    <>
      {/* Mobile sidebar */}
      <Transition.Root show={props.sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={props.setSidebarClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={props.setSidebarClose}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-900 px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-8 w-auto" src={logoTransparentWhite} alt="Willow Admin" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {sidebarNavigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                onClick={props.setSidebarClose}
                                to={item.href}
                                className={({ isActive }) =>
                                  clsx(
                                    isActive
                                      ? "bg-primary-700 text-white"
                                      : "text-gray-200 hover:text-white hover:bg-primary-500",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                  )
                                }
                              >
                                {({ isActive }) => (
                                  <>
                                    <item.icon
                                      className={clsx(
                                        isActive ? "text-white" : "text-gray-200 group-hover:text-white",
                                        "h-6 w-6 shrink-0",
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </>
                                )}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li className="mt-auto">
                        <div className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-200 hover:bg-secondary-500 hover:text-white">
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0 text-gray-200 group-hover:text-white"
                            aria-hidden="true"
                          />
                          Settings (disabled)
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary-800 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img className="h-8 w-auto" src={transparentLogo} alt="Trustwillo" />
          </div>

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {sidebarNavigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        onClick={props.setSidebarClose}
                        to={item.href}
                        className={({ isActive }) =>
                          clsx(
                            isActive
                              ? "bg-primary-700 text-white"
                              : "text-gray-200 hover:text-white hover:bg-primary-500",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                          )
                        }
                      >
                        {({ isActive }) => (
                          <>
                            <item.icon
                              className={clsx(
                                isActive ? "text-white" : "text-gray-200 group-hover:text-white",
                                "h-6 w-6 shrink-0",
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </>
                        )}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="mt-auto">
                <ul role="list" className="flex flex-1 flex-col space-y-1">
                  <li>
                    <div className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-200 hover:bg-secondary-500 hover:text-white">
                      <Cog6ToothIcon
                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                        aria-hidden="true"
                      />
                      Settings (disabled)
                    </div>
                  </li>
                  <li>
                    <a
                      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-200 hover:bg-primary-500 hover:text-white hover:cursor-pointer"
                      href={
                        import.meta.env.PROD ? "https://app.trustwillow.com/admin/" : "http://localhost:8000/admin/"
                      }
                      target="_blank"
                    >
                      <ArrowTopRightOnSquareIcon
                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                        aria-hidden="true"
                      />
                      Django admin
                    </a>
                  </li>
                  {userNavigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        relative="path"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-200 hover:bg-primary-500 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
